import React from 'react';
import Fade from 'react-reveal/Fade';
import { Container } from 'react-bootstrap';
import Title from '../Title/Title';

const Contact = () => {
  return (
    <section id="contact">
      <Container>
        <Title title=" " />
        <Fade bottom duration={1000} delay={200} distance="30px">
          <img src="img/logo_inverted_512.png" alt="velours.tv" width={300} />
          <div className="contact-wrapper">
            <p className="contact-wrapper__text">
              Velours SAS - 1 Impasse de la Baleine, 75011 Paris FRANCE
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--resume"
              href="mailto:contact@velours.tv"
            >
              mail
            </a>
          </div>
        </Fade>
      </Container>
    </section>
  );
};

export default Contact;
